import React from 'react';
import ReactPlayer from 'react-player';
import "../styles/video.css";

const Folk = () => {

    return (
        <div className='content'>
            <h1 className='guitaretitre'>Guitare Folk</h1>
            <div>
                {/* <div className='video'>

                    <ReactPlayer
                        url={"https://www.youtube.com/shorts/SD7EDz7q2eQ"}
                        controls={true}
                    />

                </div> */}
            </div>
            <div className='video'>

                <ReactPlayer
                    url={"https://www.youtube.com/shorts/TdfK6wwFvjU"}
                    controls={true}
                />

            </div>
            <div className='video'>

                <ReactPlayer
                    url={"https://youtube.com/shorts/tWrcXiGb9yU"}
                    controls={true}
                />

            </div>

            <div className='video'>

                <ReactPlayer
                    url={"https://youtube.com/shorts/kIiu3EYg9lQ"}
                    controls={true}
                />

            </div>

        </div>
    )

}

export default Folk