import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { FaBars } from 'react-icons/fa';



import "../styles/side.css"

const SideMenu = () => {
    const [isVisible, setIsVisible] = useState(false);

    function toggleBidule() {
        setIsVisible(!isVisible);
    }

    return (
        <div>
            {/* <button className='btnSide' onClick={toggleBidule}>Bonjour</button> */}

            <FaBars className='btnSide' filter="drop-shadow(2px 2px 0px black)" color='white' onClick={toggleBidule} size={25}/>

            <div className={`bidule ${isVisible ? 'visible' : 'hidden'}`}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                    {/* <button onClick={toggleBidule}>Bonjour</button> */}
                    <FaBars className='btnSide' filter="drop-shadow(2px 2px 0px black)" color='white' onClick={toggleBidule} size={25}/>
                    <NavLink to="/" onClick={toggleBidule}>
                        <a style={{fontFamily:'cursive', filter:"drop-shadow(2px 2px 0px black)" , color:"white"}}>Accueil</a>
                    </NavLink>
                    <NavLink to="/info" onClick={toggleBidule}>
                        <a style={{fontFamily:'cursive',filter:"drop-shadow(2px 2px 0px black)", color:"white"}}>Info</a>
                    </NavLink>
                </div>
            </div>
        </div>
    );
}

export default SideMenu;
