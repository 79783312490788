import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './pages/Home';
import Erreur from "./pages/Erreur";
import Navigation from "./components/Navigation";
import Info from "./pages/Info";
import Footer from "./pages/Footer";
import SideMenu from "./components/SideMenu";
import Classique from "./pages/Classique";
import Electrique from "./pages/Electrique";
import Folk from "./pages/Folk";

import "../src/styles/background.css"

function App() {
  return (


    <BrowserRouter>
      <Navigation />
      <SideMenu />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="*" element={<Erreur />} />
        <Route path="/info" element={<Info />} />
        <Route path="/classique" element={<Classique />} />
        <Route path="/electrique" element={<Electrique />} />
        <Route path="/folk" element={<Folk />} />
      </Routes>
      <Footer />
    </BrowserRouter>

  );
}

export default App;
