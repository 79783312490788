import React from 'react'
import FooterPage from '../components/FooterPage'

const Footer = () => {
 
    return (
      <div>
        <FooterPage/>
      </div>
    )
  
}

export default Footer