import React from 'react';
import { NavLink } from 'react-router-dom';
import "../styles/navigation.css"

const Navigation = () => {
    return (
        <div className="navigation">
            <NavLink to="/" >
                <a>Accueil</a>
            </NavLink>
            <NavLink to="info">
                <a>Info</a>
            </NavLink>
          
        </div>
    );
};

export default Navigation;