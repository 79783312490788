import React from 'react'
import "../styles/info.css"

const Info = () => {

  return (
    <div className='infoPage'>
      <div style={{ display: "flex", justifyContent: "center" }}>
     
        <div style={{ textAlign: "center" }}>
          <p className='infotxt'>Apprenez la guitare classique, folk ou même électrique !</p>
          <p className='infotxt'>Débutants acceptés, confirmés aussi !</p>
          <p className='infotxt'>Rendez-vous le mardi avec monsieur Deplanque.</p>
          <p className='infotxt'>N'oubliez pas votre guitare</p>
          <p></p>
          <p className='infotxt'>Possibilité pour essayer qu'on vous prête une guitare sur place</p>
          <p className='infotxt'>Pré-inscription auprès de monsieur Deplanque ou des surveillants-AED</p>
        </div>
    
      </div>
    </div>
  )

}

export default Info