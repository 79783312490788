import React from 'react';
import ReactPlayer from 'react-player';
import "../styles/video.css";

const Electrique = () => {

    return (
        <div className='content'>
               <h1 className="guitaretitre" >Guitare Électrique</h1>
            <div>
                <div className='video'>

                    <ReactPlayer
                        url={"https://www.youtube.com/shorts/PANejSejRjk"}
                        controls={true}
                    />

                </div>
            </div>
            <div className='video'>

                <ReactPlayer
                    url={"https://www.youtube.com/shorts/ywjDLqL5C-w"}
                    controls={true}
                />

            </div>
            <div className='video'>

                <ReactPlayer
                    url={"https://www.youtube.com/shorts/XH7A6kxi-H8"}
                    controls={true}
                />

            </div>
            <div className='video'>

                <ReactPlayer
                    url={"https://www.youtube.com/shorts/48pQ39-E5Vc"}
                    controls={true}
                />

            </div>
            <div className='video'>

                <ReactPlayer
                    url={"https://www.youtube.com/shorts/sJZRrmk7sSE"}
                    controls={true}
                />

            </div>
        </div>
    )

}

export default Electrique