import React, { Component } from 'react'

export class Erreur extends Component {
  render() {
    return (
      <div style={{textAlign:"center"}}>Erreur lors de la navigation</div>
    )
  }
}

export default Erreur