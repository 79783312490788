import React from 'react'
import "../styles/footer.css"

const FooterPage = () => {

  return (
    <div className='footerPage'>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>
        </div>
        <div style={{ textAlign: "center" }}>
          <p className='footertitre' >Conception du site :</p>
          <div className='linkedin'>
            <p className='footertxt'  onClick={() => { window.open("https://www.linkedin.com/in/r%C3%A9my-lemaire-1982b4250/", "_blank") }}>Developpé par Rémy Lemaire</p>
          </div>
          <div className='link' style={{ cursor: "pointer" }} onClick={() => window.open("https://pgmv.webnode.fr/", "_blank")}>
            <p className='footertxt'>Design PGMV</p>
          </div>
        </div>
        <div>
         
        </div>
      </div>

    </div>


  )
}

export default FooterPage