import React from 'react'
import { Link } from 'react-router-dom'

import "../styles/home.css"

const Home = () => {

    return (
        <div className='content'>
            <h1 className='hometitre'>Guitare au lycée du noordover</h1>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>

                <div style={{ width: "33.33%" }} >
                    <Link to="/folk" style={{ textDecoration: 'none', color: 'white' }}>
                        <p className="hometxt" >Guitare Folk</p>
                        <img className="guitarefolk" src='https://media.achat-ville.com/uploads/ales/Produit/c9/prod_photo1_27613_1560523893.png' alt="Guitare Folk" />
                    </Link>
                </div>

                <div style={{ width: "33.33%" }} >
                    <Link to="/classique" style={{ textDecoration: 'none', color: 'white',fontWeight:"bold", fontFamily:"cursive" }} >
                        <p className="hometxt" >Guitare Classique</p>
                        <img className="guitare" src='https://www.music-acoustic.com/public/produit/images/maxi/4f-produit-data-photo-7336.png' alt="Guitare Classique" />
                    </Link>
                </div>

                <div style={{ width: "33.33%" }} >
                    <Link to="/electrique" style={{ textDecoration: 'none', color: 'white' }} >
                        <p className="hometxt" >Guitare Électrique</p>
                        <img className="guitare" src='https://pngimg.com/uploads/electric_guitar/electric_guitar_PNG24194.png' alt="Guitare Électrique" />
                    </Link>
                </div>

            </div>
        </div>
    )


}

export default Home