import React from 'react';
import ReactPlayer from 'react-player';
import "../styles/video.css";
import audio from "../components/Malaguena_2_guitares.mp3";

const Classique = () => {

    return (
        <div className='content'>
            <h1 className='guitaretitre'>Guitare Classique</h1>
            <div>
                <div className='video'>

                    <ReactPlayer
                        url={"https://www.youtube.com/shorts/_K7TNmqZwRk"}
                        controls={true}
                    />

                </div>
            </div>
            <div className='video'>

                <ReactPlayer
                    url={"https://www.youtube.com/shorts/I2cCQZo9G38"}
                    controls={true}
                />

            </div>
            <div className='video'>

                <ReactPlayer
                    url={"https://www.youtube.com/shorts/d1n6PmYXbVc"}
                    controls={true}
                />

            </div>
            <div className='video' style={{ display: 'flex', flexDirection: 'column' }}>
                <p style={{
                    textAlign: 'center', fontWeight: 'bold',
                    fontFamily: 'cursive', color: 'white'
                }}>Malaguena 2 guitares</p>
                <img src='https://i1.ytimg.com/vi/d1n6PmYXbVc/hqdefault.jpg' style={{height:"300px", width:"auto", alignSelf:"center"}}></img>
                <audio src={audio} controls="true" style={{alignSelf:'center', marginTop:'10px'}}/>
            </div>
        </div>
    )


}

export default Classique